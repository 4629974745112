<template>
  <div class="mTeacher">
    <div class="headImgBox">
      <button class="justApply headImgBtn" @click.stop="toJoin">立即入驻</button>
    </div>
    <div class="content">
      <p class="title">为什么选择在河小象工作</p>
      <div class="rollCardBox" @scroll="paperScroll1">
        <div class="allCardBox" id="allCard1">
          <div class="cardItem" ref="card11">
            <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/teacher1.png" class="card1Img">
            <div class="card1Des">
              <p>竞争力的薪酬</p>
            </div>
          </div>
          <div class="cardItem" ref="card12">
            <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/teacher2.png" class="card1Img">
            <div class="card1Des">
              <p>稳步的发展空间</p>
            </div>
          </div>
          <div class="cardItem" ref="card13">
            <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/teacher3.png" class="card1Img">
            <div class="card1Des">
              <p>专业的培训</p>
            </div>
          </div>
        </div>
      </div>
      <div class="dotBox">
        <span class="dot" :class="newDot1 == 1 ? 'newDot' : ''"></span>
        <span class="dot" :class="newDot1 == 2 ? 'newDot' : ''"></span>
        <span class="dot" :class="newDot1 == 3 ? 'newDot' : ''"></span>
      </div>
      <p class="title">入驻资格</p>
      <div class="qualificationBox">
        <div class="qualificationItem1">
          <p>什么资格可以成功成为老师?</p>
        </div>
        <div class="qualificationItem2">
          <span>本科书法专业毕业</span>
          <span>5年以上书法教学经验</span>
          <span>普通话标准流利</span>
        </div>
      </div>
      <!-- <p class="title">已入驻的优秀老师展示</p>
      <div class="rollCardBox" @scroll="paperScroll2">
        <div class="allCardBox1" id="allCard2">
          <div class="cardItem1" ref="card21">
            <img src="" class="card2Img">
            <div class="card2Des">
              <p>木瓜老师  /  著名书法教学老师</p>
            </div>
          </div>
          <div class="cardItem1" ref="card22">
            <img src="" class="card2Img">
            <div class="card2Des">
              <p>木瓜老师  /  著名书法教学老师</p>
            </div>
          </div>
          <div class="cardItem1" ref="card23">
            <img src="" class="card2Img">
            <div class="card2Des">
              <p>木瓜老师  /  著名书法教学老师</p>
            </div>
          </div>
          <div class="cardItem1" ref="card24">
            <img src="" class="card2Img">
            <div class="card2Des">
              <p>木瓜老师  /  著名书法教学老师</p>
            </div>
          </div>
        </div>
      </div>
      <div class="dotBox">
        <span class="dot" :class="newDot2 == 1 ? 'newDot' : ''"></span>
        <span class="dot" :class="newDot2 == 2 ? 'newDot' : ''"></span>
        <span class="dot" :class="newDot2 == 3 ? 'newDot' : ''"></span>
        <span class="dot" :class="newDot2 == 4 ? 'newDot' : ''"></span>
      </div> -->
      <p class="title">入驻流程</p>
      <div class="flowBox">
        <div class="flowItem">
          <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/teacher4.png" class="flowImg">
          <p class="flowDes">填写信息</p>
        </div>
        <div class="flowItem">
          <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/teacher5.png" class="flowImg">
          <p class="flowDes">资质审核</p>
        </div>
        <div class="flowItem">
          <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/teacher6.png" class="flowImg">
          <p class="flowDes">入驻成功</p>
        </div>

      </div>
      <button class="justApply"  @click.stop="toJoin">立即申请</button>
    </div>
    <m-footer class="footer"></m-footer>
  </div>
</template>

<script>
import mFooter from '../components/mFooter';
export default {
  components:{
    mFooter
  },
  name: 'mTeacher',
  data () {
    return {
      newDot1: 1,
      newDot2: 1,
    }
  },
  created() {
    this.setPageSize();
  },
  mounted() {
    // this.autoScroll();
  },
  methods: {
    //立即入驻跳转
    toJoin() {
      window.open('https://www.wjx.top/vm/tU5X29y.aspx#');
    },
    setPageSize() {
      !function(e,t){function n(){t.body?t.body.style.fontSize=12*o+"px":t.addEventListener("DOMContentLoaded",n)}function d(){var e=i.clientWidth/10;i.style.fontSize=e+"px"}var i=t.documentElement,o=e.devicePixelRatio||1;if(n(),d(),e.addEventListener("resize",d),e.addEventListener("pageshow",function(e){e.persisted&&d()}),o>=2){var a=t.createElement("body"),s=t.createElement("div");s.style.border=".5px solid transparent",a.appendChild(s),i.appendChild(a),1===s.offsetHeight&&i.classList.add("hairlines"),i.removeChild(a)}}(window,document);
    },
    paperScroll1() {

      let width = this.$refs.card11.getBoundingClientRect().width;
      let left1 = this.$refs.card11.getBoundingClientRect().left;
      let left2 = this.$refs.card12.getBoundingClientRect().left;
      let left3 = this.$refs.card13.getBoundingClientRect().left;
      let newWidth = width/5*5.5;

      if (0<left1 && left1<newWidth) {
        this.newDot1 = 1;
      } else if (0<left2 && left2<newWidth) {
        this.newDot1 = 2;
      } else if (0<left3 && left3<newWidth) {
        this.newDot1 = 3;
      }
    },
    paperScroll2() {
      let width = this.$refs.card21.getBoundingClientRect().width;
      let left1 = this.$refs.card21.getBoundingClientRect().left;
      let left2 = this.$refs.card22.getBoundingClientRect().left;
      let left3 = this.$refs.card23.getBoundingClientRect().left;
      let left4 = this.$refs.card24.getBoundingClientRect().left;
      let newWidth = width/5*5.2;
      if (0<left1 && left1<newWidth) {
        this.newDot2 = 1;
      } else if (0<left2 && left2<newWidth) {
        this.newDot2 = 2;
      } else if (0<left3 && left3<newWidth) {
        this.newDot2 = 3;
      } else if (0<left4 && left4<newWidth) {
        this.newDot2 = 4;
      }
    },
    // 自动换
    autoScroll() {
      let that = this;
      setInterval(() => {
        let width = document.body.scrollWidth/2;
        let needLen = width/5*6.1;
        if (that.newDot1 === 1) {
          document.getElementById("allCard1").setAttribute('style', `transform: translateX(-${needLen}px);transition: transform .5s;`);
          that.newDot1 = 2
        } else if (that.newDot1 === 2) {
          document.getElementById("allCard1").setAttribute('style', `transform: translateX(-${needLen*2}px);transition: transform .5s;`);
          that.newDot1 = 3
        } else if (that.newDot1 === 3) {
          document.getElementById("allCard1").setAttribute('style', `transform: translateX(0px);transition: transform .5s;`);
          that.newDot1 = 1;
        }
      }, 8000);

      setInterval(() => {
        let width = document.body.scrollWidth/2;
        let needLen = width/5*6.1;
        if (that.newDot2 === 1) {
          document.getElementById("allCard2").setAttribute('style', `transform: translateX(-${needLen}px);transition: transform .5s;`);
          that.newDot2 = 2
        } else if (that.newDot2 === 2) {
          document.getElementById("allCard2").setAttribute('style', `transform: translateX(-${needLen*2}px);transition: transform .5s;`);
          that.newDot2 = 3
        } else if (that.newDot2 === 3) {
          document.getElementById("allCard2").setAttribute('style', `transform: translateX(0px);transition: transform .5s;`);
          that.newDot2 = 4;
        } else if (that.newDot2 === 4) {
          document.getElementById("allCard2").setAttribute('style', `transform: translateX(0px);transition: transform .5s;`);
          that.newDot2 = 1;
        }
      }, 8000);

      // setInterval(() => {
      //   let width = document.body.scrollWidth/2;
      //   let needLen = width/5*6.1;
      //   if (that.newDot2 === 1) {
      //     // let i = 1;
      //     // let a1 = setInterval(function() {
      //     //   i++;
      //     //   document.getElementById("allCard2").scrollLeft = needLen * i;
      //     //   if (i == 60) {
      //     //     clearInterval(a1);
      //     //   }
      //     // }, 5);
      //     document.getElementById("allCard2").scrollLeft = needLen;
      //   } else if (that.newDot2 === 2) {
      //     // let i = 1;
      //     // let b1 = setInterval(function() {
      //     //   i++
      //     //   document.getElementById("allCard2").scrollLeft = needLen*60 + needLen * i;
      //     //   if (i == 60) {
      //     //     clearInterval(b1);
      //     //   }
      //     // }, 5);
      //     document.getElementById("allCard2").scrollLeft = needLen*2;
      //   } else if (that.newDot2 === 3) {
      //     // let i = 120;
      //     // let c1 = setInterval(function() {
      //     //   i--;
      //     //   document.getElementById("allCard2").scrollLeft = needLen * i;
      //     //   if (i == 0) {
      //     //     clearInterval(c1);
      //     //   }
      //     // }, 5);
      //     document.getElementById("allCard2").scrollLeft = 0;
      //   }
      // }, 8000);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mTeacher {
  margin-top: 1.52rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: auto;
  color: #fff;
}
.headImgBox {
  position: relative;
  width: 100vw;
  height: 5.53rem;
  overflow: hidden;
  background-color: #FFFFDA;
  background-image: url('https://xcx.alicdn2.hexiaoxiang.com/web_static/teacher.png');
  background-size: 100vw 5.53rem;

}
.justApply {
  width: 8.67rem;
  height: 1.17rem;
  border-radius: 1rem;
  line-height: 1.17rem;
  font-size: 0.43rem;
  color: #fff;
  border: none;
  outline: none;
  background-color: #13B4FE;
}
.headImgBtn {
  position: absolute;
  bottom: 0.4rem;
  left: 50%;
  transform: translateX(-50%);
}
.justApply:active {
  background:rgba(19,180,254,0.6);
}
.content {
  padding: 0.85rem 0;
  width: 100vw;
  height: auto;
  background-color: #fff;
}
.title {
  margin-bottom: 1.12rem;
  width: 100vw;
  height: 0.67rem;
  line-height: 0.67rem;
  font-size: 0.67rem;
  color: #515558;
}
.rollCardBox {
  margin-bottom: 0.1rem;
  width: 100vw;
  height: 5.6rem;
  overflow: hidden;
}
.qualificationBox {
  margin-bottom: 1.12rem;
  text-align: center;
  width: 100vw;
  height: 5rem;
}
.qualificationItem1 {
  position: relative;
  z-index: 30;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7.04rem;
  height: 2.67rem;
  font-size: 0.4rem;
  color: #515558;
  font-weight: bold;
  background-color: #fff;
  box-shadow:0px 3px 10px rgba(64,63,65,0.1);
  border-radius: 0.12rem;
}
.qualificationItem2 {
  position: relative;
  top: -0.5rem;
  z-index: 10;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 7.04rem;
  height: 2.67rem;
  font-size: 0.22rem;
  color: #515558;
  font-weight: bold;
  background-color: #EEF6FD;
  box-shadow:0px 3px 10px rgba(64,63,65,0.1);
  border-radius: 0.12rem;
}
.qualificationItem2 span {
  height: 0.46rem;
  display: flex;
  align-items: flex-start;
  flex: 1;
  margin: 0 2px;
}
.flowBox {
  margin-bottom: 1.2rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100vw;
  height: 3rem;
}
.flowItem {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
}
.flowImg {
  width: 2.23rem;
  height: 2.23rem;
  background-color: #E3EBF6;
  border-radius: 1.2rem;
  overflow: hidden;
}
.flowDes {
  height: 0.35rem;
  line-height: 0.35rem;
  font-size: 0.35rem;
  color: #515558;
}
.rollCardBox {
  width: 100vw;
  height: 5.5rem;
  overflow: hidden;
  overflow-x: auto;
}
.rollCardBox::-webkit-scrollbar {
  display: none;
}
.allCardBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 17.2rem;
  height: 5.5rem;
  padding: 0 2.5rem;
}
.allCardBox1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 22.2rem;
  height: 5.5rem;
  padding: 0 2.5rem;
}
.cardItem {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  box-shadow:0px 2px 10px 0px rgba(0,0,0,0.26);
  border-radius: 0.15rem;
}
.cardItem1 {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  box-shadow:0px 2px 10px 0px rgba(0,0,0,0.26);
  border-radius: 0.15rem;
  overflow: hidden;
}
.dotBox {
  margin-bottom: 0.89rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 0.6rem;
}
.dot {
  margin: 0.3rem 0.3rem 0;
  width: 0.12rem;
  height: 0.12rem;
  border: 0.5px solid #ccc;
  border-radius: 0.1rem;
  background-color: #fff;
}
.newDot {
  background-color: #13B4FE;
}

.card1Img {
  width: 100%;
  height: 3.15rem;
  background-color: #ccc;
}
.card1Des {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 1.85rem;
  line-height: 0.64rem;
  font-size: 0.37rem;
  color: #515558;
}
.card2Img {
  width: 100%;
  height: 100%;
}
.card2Des {
  position:absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 0.18rem;
  box-sizing: border-box;
  width: 100%;
  height: 0.89rem;
  line-height: 0.89rem;
  font-size: 0.37rem;
  color: #fff;
  background-color: rgba(0,0,0,0.6)
}
</style>
